import * as React from 'react';
import {
    Block,
} from 'framework7-react';

import PageBlock from '../pageBlock/pageBlock';

export default class BottomBar extends React.Component {
    render() {

      return <PageBlock width="700px" color="#dfe4ea">
        <Block className="text-align-center" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
            <p>Cogito NTNU | styret@cogito-ntnu.no | Org.Nr. 920 628 788 </p>
        </Block>
      </PageBlock>
    }
};