import * as React from "react";
import { Button, Icon } from "framework7-react";
import Particles from "react-particles-js";

import "./greeting.css";

export default class Greeting extends React.Component {
  render() {
    return (
      <div className="greeting_block">
        <div className="greeting-logo"></div>
        <div className="left-menu">
          <Button href="mailto:styret@cogito-ntnu.no" round external outline large color="white" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
            <Icon style={{ marginRight: "6px" }} material="mail"></Icon> styret@cogito-ntnu.no
          </Button>
        </div>
        <div className="greeting_text_block">
          <div className="greeting_text_inner">
            <span className="greeting_header">Cogito NTNU</span>
            <div className="greeting_body">Cogito NTNU er en frivillig studentorganisasjon som jobber for å fremme læring og interesse for kunstig intelligens på alle nivåer.</div>
            <Button href="https://forms.gle/WNkKvPo51AP33ivp8" external className="greeting_btn" fill style={{ backgroundColor: "#FF0081" }} large round>
              Søk årets prosjekter
            </Button>
          </div>
        </div>
        <div className="greeting-avatar">
          <div className="greeting-avatar-inner"></div>
        </div>
        <div className="border-divider"></div>
        <Particles
          className="greeting-particles"
          params={{
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "grab",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 1,
                  opacity: 0.8,
                  size: 40,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.6,
                },
              },
            },
            particles: {
              color: {
                value: "#30B3EC",
              },
              links: {
                color: "#30B3EC",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 0.5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 750,
                },
                value: 90,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    );
  }
}
