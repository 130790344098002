import * as React from "react";
import { BlockTitle, Block, Row, Col, Card, CardHeader, CardContent, CardFooter, Link } from "framework7-react";
import "./infoCards.css";

import PageBlock from "../../../shared/pageBlock/pageBlock";

class ProjectsCard extends React.Component {
  render() {
    return (
      <div className="project-card">
        <div class="project-card-bg hideFullScreen" style={{ height: "240px", backgroundImage: `url(${process.env.PUBLIC_URL}/img/projects/${this.props.img})` }} />

        <div class="row-card">
          {!this.props.right && <div class="col-card project-card-bg hideMobileScreen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/projects/${this.props.img})` }} />}
          <div class="col-card">
            <BlockTitle medium>{this.props.title}</BlockTitle>
            <Block>
              <p>{this.props.text}</p>
            </Block>
          </div>
          {this.props.right && <div class="col-card project-card-bg hideMobileScreen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/projects/${this.props.img})` }} />}
        </div>
      </div>
    );
  }
}

export default class Projects extends React.Component {
  render() {
    return (
      <>
        <PageBlock width="920px">
          <div className="project-card" style={{ marginTop: "40px" }}>
            <div class="row-card">
              <div class="col-card">
                <BlockTitle large>Om Cogito NTNU</BlockTitle>
                <Block>
                  <p style={{ fontSize: "17px" }}>Cogito NTNU er en teknisk studentorganisasjon for kunstig intelligens. Hvert semester har vi prosjekter som hvem som helst kan være med på for å få praktisk erfaring ved siden av studiene. Vårt mål er å bygge et miljø hvor både nybegynnere og erfarne kan lære AI sammen!</p>
                </Block>
              </div>
              <div class="col-card project-card-bg hideMobileScreen elevation-4" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/projects/erpokerpfpwekwpkerwer.png)` }} />
            </div>
            <div class="project-card-bg hideFullScreen" style={{ height: "240px", backgroundImage: `url(${process.env.PUBLIC_URL}/img/projects/erpokerpfpwekwpkerwer.png)` }} />
          </div>
        </PageBlock>
        <br />

        <br />
        <br />
        <PageBlock width="660px">
          <BlockTitle large>Høstens prosjekter</BlockTitle>
          <Block>
            <p style={{ fontSize: "17px" }}>
              Her finner du prosjektene du kan være med på i høst.{" "}
              <Link external href="https://forms.gle/WNkKvPo51AP33ivp8">
                <b style={{ textDecoration: "underline" }}>Søk her!</b>
              </Link>
            </p>
            <p style={{ fontSize: "17px" }}>
              Søknadsfrist er <b>søndag 29. Januar kl. 23:59</b>
            </p>
          </Block>
        </PageBlock>
        <PageBlock width="880px">
          <ProjectsCard img="sunglasses-transformed.jpeg" title="NLP // Emojify" text="Har du noen gang brukt flere minutter for å finne riktig emoji? I Emojify skal vi bruke kjente dyp læringsmodeller innenfor Natural Language Processing (NLP), som BERT, til å analysere meningen med tekst og bruke klassifisering til å finne passende emojier. Vi vil angripe problemet og iterere på løsningen som en «data scientist», til vi til slutt lager en applikasjon som finner gode emojier til tekst." />
          <ProjectsCard
            right
            img="Eventyrbilde-isj.png"
            title="Tekst//Eventyr"
            text="Er du noen som kunne tenkt deg å lage ditt eget eventyr, men er hakket mer teknisk anlagt enn kreativ? Da passer dette prosjektet for deg! I dette prosjektet skal vi se nærmere på Natural Language processing (NLP). Målet er å kunne lage egne, AI-skapte eventyr.

Hvis man er usikker på hva dette innebærer kan man sjekke ut «Harry Potter and the Portrait of what Looked Like a Large Pile of Ash» som ligger til grunn for inspirasjonen til dette prosjektet.

https://www.youtube.com/watch?v=x-uDnlGJRdk&ab_channel=CrownePrince "
          />
          <ProjectsCard img="cards.jpg" title="Spill-AI // Bridge" text="Vi se på Multi Agent Reinforcement learning. Multi agent RL er vanskelig, men gjør at en kan lage AI som løser vanskelige problemer som Starcraft2 eller DOTA 2. Planen er å se på kort spillet Bridge, der 2 og 2 spillere spiller lag. Blir du med på prosjektet kommer du til å lære mer om RL, DRL og CFR, og målet er å lage AI som en kan spille mot selv." />
          <ProjectsCard right img="bilde-2.png" title="Cogitopia // Evolution" text="I dette prosjektet bruker vi natur-simulatoren Cogitopia som ble laget i høst til å teste forskjellige AI-agenter for å se hvem som er best på å overleve i naturen. Dette er en unik mulighet til å teste en bredde av forskjellige algoritmer, både innenfor evolusjonære algoritmer og reinforcement learning, samtidig som at man kan se resultatene visualisert i en kul verden!" />
          <ProjectsCard img="Picture-1.jpg" title="Computer Vision // Doctor AI" text="Har du lyst til å lære mer om computer vision og hvordan dette kan påvirke helseindustrien? I dette prosjektet vil vi ta en nærmere titt på grunnleggende modeller for datasyn, samt lage en modell for å kunne diagnosere pasienter basert på røntgen bilder. Prosjektmedlemmene vil få prøve å trene diverse mindre modeller og bidra til det større avsluttende røntgen prosjektet." />
          <ProjectsCard right img="cogitron3.PNG" title="Robotikk // Cogitron" text="Kunstig intelligens er kult, men enda kulere er det når en agent kan gå rundt og interagere med den fysiske verden. I Cogitron-prosjektet jobber vi med akkurat dette, en modulær robot som kan se det vi ser, høre det vi hører og gå der vi kan gå. Her finner man noe for alle, om du liker å skru på fysiske maskiner eller programmere hjernen som styrer det hele!" />
          <ProjectsCard img="iStock-821805046.jpeg" title="Sikker AI" text="Generell kunstig intelligens (AGI) av overmenneskelige ferdigheter har potensialet til å radikalt transformere fremtidens samfunn på godt og vondt. Verdensledende AI-labs som OpenAI etterstreber å lage AGI. Hva må til for at en fremtid med AGI er en fremtid vi ønsker å leve i? Hvilke sikkerhetsutfordringer kan vi forvente støte på? Dette er spørsmål vi kommer til å utforske, og avhengig av progresjonen kan vi forsøke å implementere forebyggende tekniske tiltak i dagens AI-systemer." />
          <br />
          <br />
        </PageBlock>
        <PageBlock width="660px">
          <BlockTitle large>Tidligere prosjekter</BlockTitle>
          <Block>
            <p style={{ fontSize: "17px" }}>
              Her finner du noen av prosjektene vi har gjennomført tidligere semester. Du kan se mer av prosjektene på vår{" "}
              <a className="external" href={"https://github.com/cogitoNTNU"}>
                Github
              </a>
              .
            </p>
          </Block>
        </PageBlock>
        <PageBlock width="880px">
          <BlockTitle medium>Høst 2022</BlockTitle>
          <ProjectsCard img="HP_F3_Prisoner_of_Azkaban_Fat_Lady_Portrait.jpg" title="Dyp Læring // Levende Bilde" text="Ønsker du å lære mer om teknologien bak deepfakes og datasyn? I prosjektet skal vi lage et digitalt maleri av eksisterende personer som reagerer på og følger med på tilskuere. (Tenk Harry Potter🧙‍♂️)" />
          <ProjectsCard right img="cogitron-project-bg.png" title="Robotikk // Cogitron" text="Kunstig intelligens er kult, men enda kulere er det når en agent kan gå rundt og interagere med den fysiske verden. I Cogitron-prosjektet jobber vi med akkurat dette, en modulær robot som kan se det vi ser, høre det vi hører og gå der vi kan gå. Her finner man noe for alle, om du liker å skru på fysiske maskiner eller programmere hjernen som styrer det hele!" />
          <ProjectsCard img="afasfasfsfd.png" title="Tekst // Auto-Math" text="I dette prosjektet skal vi bygge en liten simulering som fylles med kunstige skapninger. Disse er avhengige av å samle ressurser for å overleve. Ved å gradvis introdusere nye konsepter inspirert av vår verden, kan vi se hva slags mønstre som dukker opp, blir det krig eller blir det fred?" />
          <ProjectsCard right img="adsasdasdasd.png" title="Artificial Life //  Mini-World" text="I dette prosjektet skal vi bygge en liten simulering som fylles med kunstige skapninger. Disse er avhengige av å samle ressurser for å overleve. Ved å gradvis introdusere nye konsepter inspirert av vår verden, kan vi se hva slags mønstre som dukker opp, blir det krig eller blir det fred?" />
          <ProjectsCard img="Untitled.png" title="Spill-AI // Alpha Zero" text="Alpha Zero prosjektet vil omhandle å lage programmer liknende Googles Alpha Zero program som har slått verdensmestere i sjakk, shogi og go. Målet er å lage AI-er som kan spille ulike spill som tic-tac-toe eller sjakk. AI-en trenes ved å spille mot seg selv mange ganger og lære av egne feil. Til slutt håper vi å få en AI som kan måle seg eller være bedre enn mennesker." />
          <ProjectsCard right img="asdasdasdasd.png" title="Intro // Auto Encoder" text="I dette prosjektet skal vi se på grunnleggende nevrale nettverk og dyp læring gjennom bruken av Auto Encoders. Auto Encoders kan for eksempel brukes til å rekonstruere bildemateriell, eller brukes til klassifiseringsproblemer. Hvis du synes Kunstig intelligens virker spennende, men er usikker på hvor du skal starte, er dette et supert prosjekt for deg. Her vil vi leke oss med enkle nettverk og vi kommer til å skrive kode for å gjenkjenne håndskrift eller kode som rett og sett sier om det vi ser er en katt eller ei." />
          <ProjectsCard img="asdadasdasdasd.png" title="Tidsserier // Stock Bot" text="Er du lei av å donere pengene dine til Wall Street? Hvorfor ikke få en bot til å gjøre det for deg så du slipper alt det harde manuelle arbeidet? Vi skal nemlig lage en tradebot som skal kunne kjøpe og selge aksjer til de mest optimale tidspunktene. Prosjektmedlemmene får også bestemme hvor mye inspirasjon som skal tas fra Reddit." />
        </PageBlock>
        <PageBlock width="880px">
          <BlockTitle medium>Vår 2022</BlockTitle>
          <ProjectsCard img="werwerwer.png" title="Spill-AI // Arcade" text="Er du lei av å spille spill? Nå kan man endelig lage AI som spiller av seg selv istedet. Det er flere måter å gjøre det på, noen av de mer populære er reinforcement learning, som lærer gjennom prøving og feiling, eller imitasjon, som prøver å etterlikne opptak av mennesker som spiller. Hvilke spill vi går løs på (og hvordan) skal dere få være med på å bestemme." />
          <ProjectsCard right img="sdfsdfsdfds.png" title="Språk og Bilde // Memes" text="I dette prosjektet skal vi leke med å få en AI til å lage memes. Målet skal være å få en datamaskin til å lage originale (og forhåpentligvis artige) memes. Om vi får dette til skal vi også se på om vi kan få laget en meme maker nettside/applikasjon hvor hvem som helst kan hente et meme som er generert av AI helt gratis!" />
          <ProjectsCard img="cogitron-project-bg.png" title="Robotikk // Cogitron" text="Kunstig intelligens er kult, men enda kulere er det når en agent kan gå rundt og interagere med den fysiske verden. I Cogitron-prosjektet jobber vi med akkurat dette, en modulær robot som kan se det vi ser, høre det vi hører og gå der vi kan gå. Her finner man noe for alle, om du liker å skru på fysiske maskiner eller programmere hjernen som styrer det hele!" />
          <ProjectsCard right img="qewqweqweqew.png" title="Dyp Læring // Super Resolution" text="I dette prosjektet skal vi utforske avansert oppskalering av bilder. Dette er et morsomt problem som krever teknikker fra mange sjangre av AI. Som prosjektdeltaker vil du få praktisk erfaring med flere aspekter av dyp læring, pluss et hendig program som du selv kan ha bruk for!" />
          <ProjectsCard img="asdasdasd.png" title="Dyp Læring // Bilde-Filter" text="I dette prosjektet skal vi jobbe med StyleGAN og lignende modeller for å gjøre morsomme ting med bilder. Lag kunstverk fra bilder, eller lag dine egne ansiktsfilter. Her skal vi prøve å bygge og trene egne modeller for å generere kombinasjoner av bilder. Bli med for å lære masse om StyleGAN og hvordan man kan bruke ai til å lage bilder." />
        </PageBlock>
        <PageBlock width="880px">
          <BlockTitle medium>Høst 2021</BlockTitle>
          <ProjectsCard img="cdsdcsdcscd.png" title="Talk to Cogitron // NLP" text="I dette prosjektet skal vi få cogitos robot cogitron til å gjenkjenne tale og reagere på det som blir sagt ved hjelp av naturlig språkprossesering. Verktøy: SpeechRecognition, NLTK og Arduino" />
          <ProjectsCard right img="sdfdsfsdfsdf.png" title="AI komponist // Dyp Læring" text="I AI Komponist-prosjektet skal vi trene datamaskinen å genere helt ny musikk! For å få til dette må vi samle treningsdata fra nettet, behandle treningsdataen, og til slutt trene et dypt nevralt nettverk til å genere nyskapende musikk. Målet med prosjekter er å gi deltagerne praktisk erfaring i dyp læring og teamarbeid, i tillegg til at vi selvfølgelig bra ny musikk!" />
          <ProjectsCard img="Cogito-appprosjekt.png" title="Photo-caching // App-prosjekt (Deep learning/image processing)" text="Vi skal lage en mobilapplikasjon som lar deg gå ut og oppdage nye steder i ditt nærområde. Applikasjonen fungere likt som «geocaching» men i stede for å finne gjenstander, skal du finne og gjenskape bilder med din mobiltelefon. Vi skal bruke: React-native, python, TensorFlow.js" />
          <ProjectsCard right img="sdfsdfsdfsdf.png" title="String-art // Bio-inspirert AI" text="I String-art skal vi lage et program som genererer string art ut ifra bilder. String art er en form for kunst hvor et bilde blir danner med bruk av tråd. Vi skal bruke bio-inspirerte metoder som maurkolonier og genetiske algoritmer for å danne slike bilder." />
          <ProjectsCard img="sdsdfsdf.png" title="Robotikk // Cogitron" text="Vil du være med på å lage en robot som kan gjøre mange forskjellige ting? I fjor bygde vi første generasjon Cogitron (avbildet), og i år skal det lages en ny og forbedret versjon, samtidig som at vi programmerer og vedlikeholder originalen. Læring og moro kommer først, og prosjektet passer for alle studieretninger og klassetrinn, så lenge man er motivert og nysgjerrig." />

          <BlockTitle medium>Vår 2021</BlockTitle>
          <ProjectsCard right img="spill-ai.png" title="AI-programmering // Spill-AI" text="I dette prosjektet skal vi jobbe med å lage kunstig intelligens som kan spille dataspill. Her skal vi lage AI som bruker belønning og straff for å lære seg å spille og vi skal også se på AI som er inspirert av evolusjon og biologi. Prosjektet er perfekt for deg som liker å programmere og lage ting, og ønsker praktisk erfaring innen kunstig intelligens." />
          <ProjectsCard img="bombots.png" title="BOMBOTS // AI-konkurranse" text="Lær om AI, få programmeringserfaring og vinn kule premier!" />

          <BlockTitle medium>Høst 2020</BlockTitle>
          <ProjectsCard img="cogitron-project-bg.png" title="Robotikk // Cogitron" text="Vil du være med på å lage en robot som kan gjøre mange forskjellige ting? I fjor bygde vi første generasjon Cogitron (avbildet), og i år skal det lages en ny og forbedret versjon, samtidig som at vi programmerer og vedlikeholder originalen. Læring og moro kommer først, og prosjektet passer for alle studieretninger og klassetrinn, så lenge man er motivert og nysgjerrig." />
          <ProjectsCard right img="StyleGan.png" title="Dyp Læring // StyleGAN" text="I dette prosjektet skal vi bruke den ultramoderne StyleGAN teknologien til å skape realistiske bilder av nye, eksotiske matretter. Her vil du lære nøyaktig hva StyleGAN går ut på ved å bygge modellen opp fra bunnen av. For de som vil være med i denne gruppen, er det anbefalt at man behersker grunnleggende programmering og eventuelt har lekt litt med maskinlæring fra før." />
          <ProjectsCard img="spill-ai.png" title="AI-programmering // Spill-AI" text="I dette prosjektet skal vi jobbe med å lage kunstig intelligens som kan spille dataspill. Her skal vi lage AI som bruker belønning og straff for å lære seg å spille og vi skal også se på AI som er inspirert av evolusjon og biologi. Prosjektet er perfekt for deg som liker å programmere og lage ting, og ønsker praktisk erfaring innen kunstig intelligens." />

          <BlockTitle medium>Vår 2020</BlockTitle>
          <ProjectsCard img="arcade.png" title="Reinforcement Learning // Arcade" text="I dette prosjektet skal vi bruke Reinforcement Learning til å løse en rekke problemer. Her vil vi bruke teknikker som Q-learing og dype Q-nettverk. Målet er å bruke det vi lærer til å bygge en agent som for eksempel kan spille tetris. Dette prosjektet passer perfekt til deg som ønsker å lære mer om Reinforcement Learning, men ikke vet helt hvor du skal begynne. " />
          <ProjectsCard right img="nlp-trump.png" title="Natural language processing // Fake News" text="I dette prosjektet skal vi bruke et recurrent neural network (RNN) for å etterligne Donald Trumps særegne twitter-utsagn. Vi skal starte enkelt, og utvide modellen til vi får så realistiske resultater som mulig. Deretter skal vi utvikle en applikasjon eller nettside hvor man kan teste tekstgeneratoren selv. Hvis vi har mer tid etter dette kan vi utforske ulike metoder for tekstgenerering, og måle de opp mot hverandre." />
          <ProjectsCard img="cogitron-v2020.png" title="Robot-prosjektet // Cogitron" text="I høst lagde vi kroppen, i vår skal vi lage hjernen og en ny og bedre kropp. Roboten er utstyrt med hjul, armer, bevegelig hode, kamera, mikrofon, høytaler og mye mer. Dette gjør at vi kan programmere den til å gjøre nesten hva som helst! Man trenger ingen relevant erfaring for å ta del i dette prosjektet, så lenge man er villig til å lære nye ting." />

          <BlockTitle medium>Høst 2019</BlockTitle>
          <ProjectsCard img="aging_small.jpg" title="Dyp læring // Face aging" text="I dette prosjektet skal vi lage en kunstig intelligens som skal kunne ta inn et bilde av en person og gi ut igjen hvordan denne personen vil se ut når de blir eldre. Her vil du lære om hvordan kan generere bilder med dyp læring og hvordan vi kan trene opp to kunstige intelligenser ved at de utfordrer hverandre. Forkunnskaper: Python og det kan være en fordel om en er litt kjent med nevrale nettverk." />
          <ProjectsCard right img="go_small.jpg" title="Reinforcement Learning // AlphaZero" text="I dette prosjektet skal vi bruke et recurrent neural network (RNN) for å etterligne Donald Trumps særegne twitter-utsagn. Vi skal starte enkelt, og utvide modellen til vi får så realistiske resultater som mulig. Deretter skal vi utvikle en applikasjon eller nettside hvor man kan teste tekstgeneratoren selv. Hvis vi har mer tid etter dette kan vi utforske ulike metoder for tekstgenerering, og måle de opp mot hverandre." />
        </PageBlock>
        <br />
        <br />
        <br />
      </>
    );
  }
}
