import React from 'react';
import {
    Page,
    BlockTitle,
    Block
} from 'framework7-react';
import {Helmet} from "react-helmet";

import PageBlock from '../../shared/pageBlock/pageBlock';
import BottomBar from '../../shared/bottomBar/bottomBar';

import Greeting from './greeting/greeting';
import InfoCards from './infoCards/infoCards';

export default class extends React.Component {
  
  render() {
    return <Page>
      <Helmet>
        <title>Cogito NTNU</title>
        <meta property="og:type" content="website"/>
      </Helmet>
      <Greeting/>
      <InfoCards/>
      <BottomBar/>
    </Page>
  }
};
