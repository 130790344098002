import HomePage from './components/pages/HomePage/main';

export default [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '(.*)',
    component: HomePage
  },
];
