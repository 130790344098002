import React from 'react';
import {
  App,
  Panel,
  View,
  List,
  BlockTitle,
  ListItem,
  Block,
  Button,
} from 'framework7-react';


import routes from '../routes';

  // Framework7 parameters here
  const f7params = {
    id: 'no.iwd.event', // App bundle ID
    name: 'Påmeldinger.no', // App name
    theme: 'md',
    stackPages: true,
    // App routes
    routes,
    view: {
      animate: false,
      pushState: true,
      pushStateSeparator:"",
    },
  };

  export default class extends React.Component {

    render = () => {
      return (
          <App params={f7params}>
            <Panel right>
              <Block style={{backgroundColor:'var(--f7-bars-bg-color)',height:'110px',marginTop:0,paddingTop:'32px'}}>
                <BlockTitle large style={{float:'left',color:'#ffffff'}}>Meny</BlockTitle>
                <Button panelClose style={{width:"48px", float:'right'}} raised outline large iconMaterial="close" round color="white"></Button>
              </Block>
              <List>
                <ListItem panelClose title="Arrangementer" link="/arrangementer"></ListItem>
                <ListItem panelClose title="Prosjekter" link="/prosjekter"></ListItem>
                <ListItem panelClose title="Verv" link="/verv"></ListItem>
                <ListItem panelClose title="om oss" link="/om"></ListItem>
              </List>
            </Panel>
            <View id="main-view" url="/" main className="safe-areas"/>
          </App>
      )
    }
  };